import { getCurrentScope } from '@sentry/react';
import { type Primitive } from 'type-fest';
import lazyLogRocket from 'lazy-logrocket';

export const setSentryContext = ({
  errorMessage,
  origin,
  requestData,
  responseData,
  tags,
}: {
  errorMessage: string;
  origin: string;
  requestData?: unknown;
  responseData?: unknown;
  tags?: {
    key: string;
    value: Primitive;
  }[];
}) => {
  const scope = getCurrentScope();

  tags?.forEach(({ key, value }) => scope.setTag(key, value));

  scope.setContext('context', {
    errorMessage,
    logRocketSessionUrl: lazyLogRocket.sessionURL,
    requestData,
    responseData,
    'solve-origin': origin,
  });
};

export const getSentryTags = async () => {
  return [];
};

export const networkErrorMessages = [
  'Network Error',
  'Request aborted',
  'timeout exceeded',

  // "Failed to fetch" errors (different browsers use different error messages
  // for the same error):
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'cancelled',

  // Other weird network errors:
  'The Internet connection appears to be offline.',
  'The network connection was lost.',
  'The operation couldn’t be completed. Software caused connection abort',
  'La connexion Internet semble interrompue.', // French: The internet connection seems to be interrupted.
  '已取消', // Chinese: Cancelled
  'Kumottu', // Finnish: Repealed
];

export const isIgnorableNetworkError = (error: unknown) =>
  error instanceof Error && networkErrorMessages.includes(error.message);
