import customFetch from './customFetch';

export const apiRoute = {
  'restart-conversation': {
    method: 'put',
    url: '/workflow/conversation/{conversationId}/restart',
  },
  'tracking-events': {
    method: 'post',
    url: '/workflow/tracking-event',
  },
  'upload-file': {
    method: 'post',
    url: '/workflow/upload-file',
  },
  'widget-config': {
    method: 'post',
    url: '/workflow/widget-config',
  },
} as const;

export const apiUploadZendeskAttachment = async (
  url: string,
  file: File,
): Promise<string> => {
  const fileName = encodeURIComponent(file.name);
  const newUrl = `${url}?filename=${fileName}`;
  const res = await customFetch<{ upload: { token: string } }>(newUrl, {
    body: file,
    headers: {
      'Content-Type': 'application/binary',
    },
    method: 'post',
  });

  return res.upload.token;
};

export const postFileToS3 = async (
  presignedS3Url: string,
  contentType: string,
  file: File,
) => {
  try {
    return await customFetch(presignedS3Url, {
      body: file,
      headers: {
        'content-type': contentType,
      },
      method: 'put',
    });
  } catch (error) {
    return false;
  }
};
