import { type FrechchatCallbackResponse } from './freshchat';
import { type ZendeskDepartments, type ZendeskWidget } from './zendesk';
import {
  AdditionalParameters,
  GenericHandoffEventData,
  IntercomChatAutoSendConfig,
  KustomerCallbackResponse,
  FormattedSalesforceExtraPrechatFormDetails,
  BrandEmbassyCustomFields,
  HandoffIntegrations,
  GenericHandoffWidgetOption,
  HandoffData,
} from '../api/types';
import { IntercomChatSettings } from './intercom';
import {
  ForethoughtWidgetIntegrationHandoffCompletedEvent,
  PostMessage,
} from './embed';
import { postMessage as postMessageAllPlatforms } from '../../src/utils/postMessage';
import { callWithRetry, getExceptionMessage, wait } from './utils';
import normalizeTargetOrigin from 'utils/normalizeTargetOrigin';
import { FORETHOUGHT_IFRAME_ID } from './constants';
import { sendErrorToSentry } from 'utils/sendErrorToSentry';

const doc = window.document;
const currentScript = doc.currentScript;
const hideWidgetAfterZD =
  currentScript?.getAttribute('hide-ft-after-zd') === 'true';
const nonce = currentScript?.getAttribute('nonce');
const autoOpenZd = currentScript?.getAttribute('auto-open-zd') !== 'false';
const hideIntercomWidget = currentScript?.getAttribute('hide-intercom-widget');

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////// HANDOFF /////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////

let questionGlobal = '';
let salesforceAfterDestroyCallbackSet = false;

// Helper function to trigger return on Salesforce chat safely
function triggerEvent(el: HTMLElement, type: 'keydown', keyCode: number) {
  if ('createEvent' in doc) {
    // modern browsers, IE9+
    const e = doc.createEvent('HTMLEvents') as any;
    e.keyCode = keyCode;
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  }
}

export const initWidgetHandoff = () => {
  const ifrm = doc.getElementById(FORETHOUGHT_IFRAME_ID) as HTMLIFrameElement;
  const currentScriptSrc = currentScript?.getAttribute('src');
  const targetOrigin = currentScriptSrc
    ? normalizeTargetOrigin(new URL(currentScriptSrc).origin)
    : '*';

  const isEventTrusted = (event: MessageEvent) =>
    Boolean(
      (event.origin === targetOrigin || targetOrigin === '*') && event.data,
    );

  const postMessage = (obj: PostMessage) => {
    const iframe = ifrm || doc.getElementById(FORETHOUGHT_IFRAME_ID);
    if (iframe) {
      iframe.contentWindow?.postMessage(obj, targetOrigin);
    } else {
      sendErrorToSentry({
        consoleError: true,
        error: 'Forethought iframe not found -- cannot postMessage to it',
      });
    }
  };

  const postUpdateHelpdeskConversation = (
    integration: HandoffIntegrations,
    helpdeskConversationId: string,
    forethoughtConversationID: string | null = null,
    includeAttachments: boolean | null = false,
    includeTranscript: boolean | null = true,
  ) => {
    postMessage({
      event: 'updateHelpdeskConversation',
      forethoughtConversationID: forethoughtConversationID,
      helpdeskConversationId: helpdeskConversationId,
      includeAttachments: includeAttachments,
      includeTranscript: includeTranscript,
      integration: integration,
    });
  };

  function createStyleElement(id: string): HTMLStyleElement {
    const styleElement = doc.createElement('style');
    styleElement.id = id;

    if (nonce) {
      styleElement.nonce = nonce;
    }

    return styleElement;
  }

  //#region salesforce

  const salesforceStyle = createStyleElement('ft-salesforce-style');

  const showSalesforceWidget = () => {
    salesforceStyle.innerHTML = '';
    doc.body.appendChild(salesforceStyle);
  };

  const hideSalesforceWidget = () => {
    salesforceStyle.innerHTML =
      '.embeddedServiceHelpButton { display: none !important;}';
    doc.body.appendChild(salesforceStyle);
  };

  const refreshingPageWithChat = () => {
    if (
      window.embedded_svc.liveAgentAPI.browserSessionInfo.activeChatSessions
    ) {
      window.Forethought('widget', 'hide');
    }
  };

  const sfMessagingSendMessage = () => {
    let tryAgain = true;
    let attemptCount = 0;
    const retrySfMessagingSendMessage = () => {
      if (tryAgain && attemptCount <= 10) {
        tryAgain = false;
        // Find text area
        const sfTextArea = doc.getElementsByClassName(
          'chasitorText',
        )[0] as HTMLInputElement;
        if (sfTextArea) {
          sfTextArea.value = questionGlobal;
          // Send question
          triggerEvent(sfTextArea, 'keydown', 13);
        } else {
          tryAgain = true;
          attemptCount++;
          setTimeout(retrySfMessagingSendMessage, 1000);
        }
      }
    };
    retrySfMessagingSendMessage();
  };

  const salesforceChatEnded = () => {
    hideSalesforceWidget();
    window.Forethought('widget', 'show');
  };

  /**
   * @example
   * // returns { firstName: 'Joe', lastName: 'John Jane'}
   * splitNameIntoFirstAndLast('Joe John Jane')
   * // returns { firstName: 'Joe', lastName: 'John'}
   * splitNameIntoFirstAndLast(' Joe   John ')
   * // returns { firstName: 'Joe', lastName: 'Joe'}
   * splitNameIntoFirstAndLast('Joe')
   */
  const salesforceSplitNameIntoFirstAndLast = (
    name: string,
  ): { firstName: string; lastName: string } => {
    // trim whitespace and replace 2 or more spaces with a single space
    const cleanedName = name.trim().replace(/\s{2,}/g, ' ');
    const names = cleanedName.split(' ');
    const firstName = names.length && names[0] ? names[0] : '';
    const lastName = names.length > 1 ? names.slice(1).join(' ') : firstName;

    return {
      firstName,
      lastName,
    };
  };

  if (window.embedded_svc) {
    window.embedded_svc.addEventHandler('afterDestroy', salesforceChatEnded);
    salesforceAfterDestroyCallbackSet = true;
  }

  //#endregion salesforce

  //#region zendesk

  const zdMessagingStyle = createStyleElement('ft-zd-style');

  const hideZdMessagingAndWidget = () => {
    // This can fail (for messaging webWidget doesn't exist)
    // and won't constitute a failed handoff
    try {
      if (window.zE) window.zE('webWidget', 'hide');
    } catch {}
    try {
      if (window.zE) window.zE('messenger', 'hide');
    } catch {}
    zdMessagingStyle.innerHTML = `
      iframe[id="launcher"] { display: none !important;}
      iframe[title="Button to launch messaging window"] { display: none !important;}
      iframe[title="Button to launch messaging window, conversation in progress"] { display: none !important;}
      iframe[title="Message from company"] { display: none !important;}
      iframe[data-product="web_widget"] { display: none !important ;}
      `;

    doc.body.appendChild(zdMessagingStyle);
  };

  const showZdMessagingAndWidget = () => {
    // This can fail (for messaging webWidget doesn't exist)
    // and won't constitute a failed handoff
    try {
      if (window.zE) window.zE('webWidget', 'show');
    } catch {}
    try {
      if (window.zE) window.zE('messenger', 'show');
    } catch {}
    zdMessagingStyle.innerHTML = `
      iframe[id="launcher"] { display: block !important;}
      iframe[title="Button to launch messaging window"] { display: block !important;}
      iframe[title="Button to launch messaging window, conversation in progress"] { display: block !important;}
      iframe[title="Message from company"] { display: block !important;}
      iframe[data-product="web_widget"] { display: block !important;}
    `;

    doc.body.appendChild(zdMessagingStyle);
  };

  const getDepartments: () => ZendeskDepartments = () => {
    if (!window.zE) {
      postMessage({
        event: 'noZendeskObjectDetected',
      });
      return [];
    }

    try {
      return window.zE('webWidget:get', 'chat:departments') || [];
    } catch {
      return [];
    }
  };

  const getDepartmentsLoop = async () => {
    let departments: ZendeskDepartments = [];
    try {
      const numOfTimesToFire = 20;
      const delayAfterEachFire = 500;

      for (let i = 0; i < numOfTimesToFire; ++i) {
        await wait(delayAfterEachFire);
        if (window.zE) {
          departments = window.zE('webWidget:get', 'chat:departments') || [];
          if (departments.length > 0) {
            break;
          }
        }
      }
    } catch (error) {
      departments = [];
    }
    return departments;
  };

  const getOnlineDepartments = () => {
    return getDepartments().filter(dept => dept.status === 'online');
  };

  // Function that gets triggered on load and checks if zd client is available
  const zdHandOff = (zE: ZendeskWidget, isChatting: boolean) => {
    try {
      //  If there is not a chat in progress hide ZD widget on load
      if (!isChatting) hideZdMessagingAndWidget();
      else {
        //  If there is  a chat in progress hide our solve widget on load
        showZdMessagingAndWidget();
        if (autoOpenZd) {
          zE('webWidget', 'open');
        }
        window.Forethought('widget', 'hide');
      }

      // setup listener to kill Ze widget when ending chat + Show agatha
      zE('webWidget:on', 'chat:end', function () {
        if (!zE) return;

        // close FT widget no matter what so that when we reshow it is closed
        window.Forethought('widget', 'close');

        if (!hideWidgetAfterZD) {
          // hide ZD widget and show Forethought
          zE('webWidget', 'close');
          hideZdMessagingAndWidget();
          window.Forethought('widget', 'show');
        }
      });
    } catch {
      return;
    }
  };

  // Function that gets triggered on load and checks if zd client is available
  const zdHandOffAttempt = (zE?: ZendeskWidget) => {
    if (!zE) return;
    let isChatting = false;
    // Zendesk messaging does not have chat:isChatting method
    try {
      isChatting = zE('webWidget:get', 'chat:isChatting') as unknown as boolean;
    } catch {}
    if (typeof isChatting !== 'boolean') return false;
    zdHandOff(zE, isChatting);
    return true;
  };

  const getIsChatting = () => {
    if (!window.zE) {
      postMessage({
        event: 'noZendeskObjectDetected',
      });
      return false;
    }
    // Zendesk messaging does not have chat:isChatting method
    try {
      const isChatting = window.zE('webWidget:get', 'chat:isChatting');
      if (typeof isChatting !== 'boolean') return false;
      return isChatting;
    } catch {
      return false;
    }
  };

  //#endregion zendesk

  //#region zendesk messaging

  const zdMessagingSendMessage = (question: string, callback: () => void) => {
    let tryAgain = true;
    let attemptCount = 0;
    const retryZdMessagingSendMessage = () => {
      if (tryAgain && attemptCount <= 10) {
        tryAgain = false;

        // The handoff can be executed from a manually selected intent so if no question
        // just set handoff status to true and return
        if (!question) {
          callback();
          return;
        }

        // Add in question and send. Note that in this case we're not dealing
        // with a launcher, but with an expanded chat window - that one doesn't
        // have an id, and only has a title we can select by
        const zdMessagingIframe: HTMLIFrameElement | null = doc.querySelector(
          'iframe[title="Messaging window"]',
        );
        const zdTextArea: HTMLTextAreaElement | null | undefined =
          zdMessagingIframe?.contentWindow?.document.querySelector(
            'textarea[data-garden-id="forms.textarea"]',
          );

        if (zdTextArea) {
          Object.getOwnPropertyDescriptor(
            window.HTMLTextAreaElement.prototype,
            'value',
          )?.set?.call(zdTextArea, question);
          zdTextArea.dispatchEvent(new Event('change', { bubbles: true }));

          // hit send button
          const zdSubmitButton: HTMLButtonElement | null | undefined =
            zdMessagingIframe?.contentWindow?.document.querySelector(
              'button[data-garden-id="buttons.icon_button"][aria-label="Send message"]',
            );

          if (zdSubmitButton) {
            zdSubmitButton.click();
            callback();
          }
        } else {
          tryAgain = true;
          attemptCount++;
          setTimeout(retryZdMessagingSendMessage, 3000);
        }
      } else {
        callback();
      }
    };
    retryZdMessagingSendMessage();
  };

  //#endregion zendesk messaging

  //#region kustomer

  const kustomerStyle = createStyleElement('ft-kustomer-style');

  const hideKustomerChatWidget = () => {
    kustomerStyle.innerHTML =
      '#kustomer-ui-sdk-iframe { display: none !important;}';
    doc.body.appendChild(kustomerStyle);
  };

  const showKustomerWidget = () => {
    kustomerStyle.innerHTML =
      '#kustomer-ui-sdk-iframe { display: block !important;}';
    doc.body.appendChild(kustomerStyle);
  };

  //#endregion kustomer

  //#region Intercom

  const intercomStyle = createStyleElement('ft-intercom-style');

  const showIntercomWidget = () => {
    intercomStyle.innerHTML = '';
    doc.body.appendChild(intercomStyle);
  };

  const hideIntercomChatIconAndWidget = () => {
    // hide if Intercom script is on window
    if (window.Intercom) {
      window.Intercom('hide');
    }

    // hide Intercom widget via CSS
    intercomStyle.innerHTML = `
      .intercom-launcher {display: none !important;}
      .intercom-messenger-frame {display: none !important;}
      .intercom-lightweight-app {display: none !important;}
      .intercom-launcher-frame {display: none !important;}
      [class*="intercom-with-namespace-"] span[data-testid="active-notifications"]{display: none !important;}
      iframe[name="intercom-borderless-frame"] { display: none !important; }
      iframe[name="intercom-notifications-frame"] { display: none !important; }
    `;
    doc.body.appendChild(intercomStyle);
  };

  const handOffToIntercom = ({
    autoSendConfig,
    email,
    identityVerificationEnabled,
    isCurrentTabPrimary,
    name,
    question,
    userid,
  }: {
    autoSendConfig: IntercomChatAutoSendConfig;
    email: string;
    identityVerificationEnabled: boolean;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
    userid: string;
  }) => {
    const updatedIntercomSettings: IntercomChatSettings = {
      ...window.intercomSettings,
    };

    // If an intercom instance has Identity Verification enabled we
    // cannot send this info to Intercom, if we do we get an error saying
    // a "valid user_hash is required to authenticate users when Identity Verification
    // is enabled"
    if (!identityVerificationEnabled) {
      if (name) updatedIntercomSettings.name = name;
      if (userid) updatedIntercomSettings.user_id = userid;
      if (email) updatedIntercomSettings.email = email;
    }

    // update intercom settings
    window.Intercom('update', updatedIntercomSettings);

    // remove display: none injected styles
    showIntercomWidget();

    if (question && isCurrentTabPrimary) {
      // show intercom with question pre-populated
      window.Intercom('showSpace', 'messages');
      // setTimeout is necessary here or else it will only show the messages homescreen
      setTimeout(() => {
        window.Intercom('showNewMessage', question);
      }, 1);

      // check if we should attempt to auto send the message for the user
      if (autoSendConfig && autoSendConfig.should_auto_send_message) {
        setTimeout(() => {
          const intercomIframe: HTMLIFrameElement | null = doc.querySelector(
            'iframe[name="intercom-messenger-frame"]',
          );

          if (intercomIframe) {
            const intercomSendMessageButton:
              | HTMLButtonElement
              | null
              | undefined =
              intercomIframe.contentWindow?.document.querySelector(
                '.intercom-composer-send-button',
              );

            if (intercomSendMessageButton) {
              intercomSendMessageButton.click();
            }
          }
        }, autoSendConfig.auto_send_timeout);
      }
    } else {
      window.Intercom('show');
    }
  };

  //#endregion

  //#region SnapEngage

  const snapEngageStyle = createStyleElement('ft-snapengage-style');

  const showSnapEngageWidget = () => {
    if (window.SnapEngage) {
      window.SnapEngage.showButton();
    }

    snapEngageStyle.innerHTML =
      '#designstudio-button { display: block !important;}';
    doc.body.appendChild(snapEngageStyle);
  };

  const hideSnapEngageChatIconAndWidget = () => {
    // hide if SnapEngage script is on window
    if (window.SnapEngage) {
      window.SnapEngage.hideButton();
    }

    snapEngageStyle.innerHTML =
      '#designstudio-button { display: none !important;}';
    doc.body.appendChild(snapEngageStyle);
  };

  const handOffToSnapEngage = ({
    additionalParameters,
    email,
    isCurrentTabPrimary,
    name,
    question,
  }: {
    additionalParameters: AdditionalParameters;
    email: string;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
  }) => {
    // update snapengage settings
    if (email) window.SnapEngage.setUserEmail(email, true);
    if (name) window.SnapEngage.setUserName(name);

    // change display: none to display: block
    showSnapEngageWidget();

    // Only send the message if this is the primary tab
    if (isCurrentTabPrimary) {
      // populate snapengage chat with question from user (manually)
      window.SnapEngage.startLink();
      const SnapEngageIFrame = doc.getElementById(
        'designstudio-iframe',
      ) as HTMLIFrameElement;
      const textAreaElement =
        SnapEngageIFrame.contentWindow?.document.getElementById(
          'message-textarea',
        ) as HTMLTextAreaElement;
      if (textAreaElement && question) textAreaElement.value = question;
      // Send transcript to SnapEngage during handoff
      if (additionalParameters?.transcript && window.SnapEngage) {
        window.SnapEngage.setCallback('ChatMessageReceived', function (agent) {
          const retrySnapEngageMessageMethod = () => {
            let tryAgain = true;
            try {
              if (tryAgain) {
                tryAgain = false;
                // Send a message to the agent
                window.SnapEngage.sendTextToChat(
                  additionalParameters?.transcript,
                );
              }
            } catch (err) {
              tryAgain = true;
              window.SnapEngage.setCallback('ChatMessageReceived', '');
              setTimeout(retrySnapEngageMessageMethod, 5000);
            }
          };
          // Ignore blend bots
          const bot_alias_list: string[] | null =
            additionalParameters?.bot_alias_list;
          if (bot_alias_list && agent && !bot_alias_list.includes(agent)) {
            retrySnapEngageMessageMethod();
            window.SnapEngage.setCallback('ChatMessageReceived', '');
          }
        });
      }
    }

    // If a chat finishes and user closes chat
    // Instead of a showing minimized SE button, show our widget minimized
    if (window.SnapEngage) {
      window.SnapEngage.setCallback('Close', function () {
        hideSnapEngageChatIconAndWidget();
        window.Forethought('widget', 'show');
      });
    }
  };

  //#endregion

  //#region Drift
  const showDriftWidget = () => {
    if (window.drift) window.drift.api.openChat();
  };

  const hideDriftChatIconAndWidget = () => {
    if (window.drift) window.drift.hide();
  };

  const handOffToDrift = ({
    email,
    isCurrentTabPrimary,
    name,
    question,
  }: {
    email: string;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
  }) => {
    // set user attribute
    window.drift.on('ready', function () {
      const formInput: { email?: string; nickname?: string } = {};
      if (email) formInput.email = email;
      if (name) formInput.nickname = name;
      window.drift.api.setUserAttributes(formInput);
    });
    // start conversation with a question if this is the primary tab
    if (question && isCurrentTabPrimary) {
      window.drift.api.goToNewConversation(question);
    }
    // show drift button w/ open chat
    showDriftWidget();
  };

  //#endregion

  //#region generic

  const genericHandleWidget = (option: GenericHandoffWidgetOption) => {
    switch (option) {
      case 'hide':
        window.Forethought('widget', 'hide');
        return;
      case 'minimize':
        window.Forethought('widget', 'close');
        return;
      default:
        return;
    }
  };

  const genericHandoff = (
    widgetOption: GenericHandoffWidgetOption,
    eventData: GenericHandoffEventData,
  ): void => {
    genericHandleWidget(widgetOption);

    postMessageAllPlatforms({
      ...eventData,
      event: 'forethoughtWidgetGenericHandoff',
    });
  };

  //#endregion generic

  //#region Brand Embassy
  const brandEmbassyStyle = createStyleElement('ft-brand-embassy-style');

  const hideBrandEmbassyIconAndWidget = () => {
    brandEmbassyStyle.innerHTML = '#be-frame {display: none !important;}';
    doc.body.appendChild(brandEmbassyStyle);
  };

  const showBrandEmbassyIconAndWidget = () => {
    brandEmbassyStyle.innerHTML = '#be-frame {display: block !important;}';
    doc.body.appendChild(brandEmbassyStyle);
  };

  const handoffToBrandEmbassy = ({
    autoStartSession,
    customFields,
    isCurrentTabPrimary,
    name,
    question,
  }: {
    autoStartSession: boolean;
    customFields: BrandEmbassyCustomFields;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
  }) => {
    // docs: https://support.brandembassy.com/hc/en-us/articles/360016024553-Javascript-API
    // set name
    if (name) {
      window.brandembassy('setCustomerName', name);
    }

    // set custom fields
    if (customFields) {
      for (const [cf_key, cf_value] of Object.entries(customFields)) {
        window.brandembassy('setCustomField', cf_key, cf_value);
      }
    }

    // only send message if primary tab
    if (isCurrentTabPrimary) {
      // show first message and overwrite it to user question
      window.brandembassy('hideFirstSentMessage', false);
      window.brandembassy('sendFirstMessageAutomatically', question);
    }

    // show and open brand embassy widget
    showBrandEmbassyIconAndWidget();
    window.brandembassy('openChatWindow');

    // start chat session - this must be called last, but only if this is the primary tab
    if (autoStartSession && isCurrentTabPrimary) {
      window.brandembassy('autoStartSession');
    }
  };
  //#endregion

  //#region Gorgias
  const gorgiasStyle = createStyleElement('ft-gorgias-style');

  const hideGorgiasIconAndWidget = () => {
    gorgiasStyle.innerHTML =
      '#gorgias-chat-container {display: none !important;}';
    doc.body.appendChild(gorgiasStyle);
  };

  const showGorgiasIconAndWidget = () => {
    gorgiasStyle.innerHTML =
      '#gorgias-chat-container {display: block !important;}';
    doc.body.appendChild(gorgiasStyle);
  };

  const handoffToGorgias = ({
    isCurrentTabPrimary,
    question,
  }: {
    isCurrentTabPrimary: boolean;
    question: string;
  }) => {
    showGorgiasIconAndWidget();

    window.GorgiasChat.hideChat(false);
    window.GorgiasChat.open();

    if (isCurrentTabPrimary) {
      window.GorgiasChat.sendMessage(question);
    }
  };
  //#endregion

  //#region Freshchat
  const freshchatStyle = createStyleElement('ft-freshchat-style');

  const hideFreshchatIconAndWidget = () => {
    freshchatStyle.innerHTML = `
      #fc_frame {display: none !important;}
      freshchat-widget {display: none !important;}
    `;
    doc.body.appendChild(freshchatStyle);
  };

  const showFreshchatIconAndWidget = () => {
    freshchatStyle.innerHTML = '';
    doc.body.appendChild(freshchatStyle);
  };

  const configureFreshchatMessageListener = (
    forethoughtConversationID: string | null,
  ) => {
    const includeAttachments = false;
    const includeTranscript = true;
    // Subscribe to the event triggered when a message is sent
    // see https://developers.freshchat.com/web-sdk/v1/#messenger-events
    window.fcWidget.on(
      'message:sent',
      function (data: FrechchatCallbackResponse) {
        postUpdateHelpdeskConversation(
          'freshchat',
          data.message.conversationAlias,
          forethoughtConversationID,
          includeAttachments,
          includeTranscript,
        );
        // Once the transcript is sent, we can unsubscribe to this event so we don't posting the transcript
        window.fcWidget.off('message:sent');
      },
    );
  };

  const handoffToFreshchat = ({
    conversationProperties,
    email,
    firstName,
    forethoughtConversationID,
    isCurrentTabPrimary,
    lastName,
    question,
    tags,
  }: {
    conversationProperties: Record<string, unknown> | null;
    email: string;
    firstName: string;
    forethoughtConversationID: string | null;
    isCurrentTabPrimary: boolean;
    lastName: string;
    question: string;
    tags: string[] | null;
  }) => {
    const userProperties: Record<string, string> = {};
    const widgetOpenParams: { replyText: string } = { replyText: '' };

    if (firstName) {
      userProperties.firstName = firstName;
    }

    if (lastName) {
      userProperties.lastName = lastName;
    }

    if (email) {
      userProperties.email = email;
    }

    // set values
    if (Object.keys(userProperties).length) {
      window.fcWidget.user.setProperties(userProperties);
    }

    // only configure the listener and send message, set tags,
    // and send message on primary tab
    if (isCurrentTabPrimary) {
      configureFreshchatMessageListener(forethoughtConversationID);

      if (tags) {
        window.fcWidget.setTags(tags);
      }

      if (
        conversationProperties &&
        Object.keys(conversationProperties).length
      ) {
        window.fcWidget.conversation.setConversationProperties({
          ...conversationProperties,
        });
      }

      // only add a question if it's the primary tab
      widgetOpenParams.replyText = question;
    }

    showFreshchatIconAndWidget();
    window.fcWidget.open(widgetOpenParams);
  };
  //#endregion

  //#region Salesforce Messaing for Web
  const salesforceMessagingStyle = createStyleElement(
    'ft-salesforce-messaging-style',
  );

  const hideSalesforceMessagingIconAndWidget = () => {
    salesforceMessagingStyle.innerHTML = `
      #embedded-messaging {display: none !important;}
      #embedded-service {display: none !important;}
    `;
    doc.body.appendChild(salesforceMessagingStyle);
  };

  const showSalesforceMessagingIconAndWidget = () => {
    salesforceMessagingStyle.innerHTML = '';
    doc.body.appendChild(salesforceMessagingStyle);
  };

  const clickSalesforceMessagingIframeButton = (
    forethoughtConversationID: string | null,
  ) => {
    try {
      const sfIframe = doc.getElementById(
        'embeddedMessagingFrame',
      ) as HTMLIFrameElement | null;
      if (sfIframe) {
        const sfIframeDocument =
          sfIframe.contentDocument || sfIframe.contentWindow?.document;
        if (sfIframeDocument) {
          const sfIframeButton = sfIframeDocument
            ?.querySelector('webruntime-app')
            ?.querySelector(
              'button.slds-button.minimizedButton',
            ) as HTMLButtonElement | null;
          if (sfIframeButton) {
            sfIframeButton.click();
          }
        }
      }
    } catch (err) {
      sendErrorToSentry({
        consoleError: true,
        conversationId: forethoughtConversationID ?? undefined,
        error: `Error clicking Salesforce Messaging Iframe button -- ${err}`,
      });
    }
  };

  /**
   * if sf messaging widget is opened then the button will have
   * `title="Minimize the chat window"`, we only want to click it if it is not open already
   */
  const clickSalesforceMessagingButton = () => {
    const button = doc.querySelector(
      '#embeddedMessagingConversationButton:not([title="Minimize the chat window"])',
    ) as HTMLButtonElement | null;

    if (button) {
      button.click();
    }
  };

  const clickSalesforceFabButton = () => {
    doc.getElementById('esw-fab')?.click();
  };

  const handoffToSalesforceMessaging = async ({
    clearSessionBeforeHandoff,
    forethoughtConversationID,
    hiddenFields,
    isCurrentTabPrimary,
  }: {
    clearSessionBeforeHandoff: boolean;
    forethoughtConversationID: string | null;
    hiddenFields: Record<string, string>;
    isCurrentTabPrimary: boolean;
  }) => {
    if (isCurrentTabPrimary) {
      // clear session
      if (clearSessionBeforeHandoff) {
        await window.embeddedservice_bootstrap.userVerificationAPI.clearSession();
      }

      // hidden fields
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(
        hiddenFields,
      );

      // launch chat
      try {
        showSalesforceMessagingIconAndWidget();
        await window.embeddedservice_bootstrap.utilAPI.launchChat();
      } catch (err) {
        // only manually click the button if the launchChat fails
        sendErrorToSentry({
          consoleError: true,
          conversationId: forethoughtConversationID ?? undefined,
          error: `Error calling window.embeddedservice_bootstrap.utilAPI.launchChat -- ${err}`,
        });
        // order matters here
        clickSalesforceMessagingButton();
        clickSalesforceMessagingIframeButton(forethoughtConversationID);
        clickSalesforceFabButton();
      }
    } else {
      showSalesforceMessagingIconAndWidget();
    }
  };
  //#endregion

  //#region LiveChat

  const liveChatStyle = createStyleElement('ft-live-chat-style');

  const hideLiveChatWidget = () => {
    /**
     * If the page does not have LiveChatWidget defined,
     * they may be running the old version, which uses
     * LC_API. We'll attempt to hide whichever version
     * is defined
     */
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('hide');
    } else if (window.LC_API) {
      window.LC_API.hide_chat_window();
    }

    liveChatStyle.innerHTML =
      '#chat-widget-container {display: none !important;}';
    doc.body.appendChild(liveChatStyle);
  };

  const showLiveChatWidget = () => {
    liveChatStyle.innerHTML =
      '#chat-widget-container {display: block !important;}';
    doc.body.appendChild(liveChatStyle);
  };

  const liveChatHandoff = () => {
    const liveChat = window.LC_API;

    // bypass linter, liveChat should be defined when this func is executed
    if (!liveChat) {
      return false;
    }

    if (liveChat.chat_running()) {
      window.Forethought('widget', 'hide');
      liveChat.open_chat_window();
      showLiveChatWidget();
      return true;
    }

    return false;
  };

  // Function that gets triggered on load and checks if live chat widget is running
  const liveChatHandoffAttempt = () => {
    const liveChat = window.LC_API;
    if (!liveChat) return false;
    if (!liveChat.is_loaded()) return false;
    return liveChatHandoff();
  };

  const handoffToLiveChatV1Api = ({
    email,
    isCurrentTabPrimary,
    name,
    question,
  }: {
    email: string;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
  }) => {
    /**
     * Performs handoff to LiveChat using API version 1.0. This is deprecated,
     * but still does get some use.
     * https://platform.text.com/docs/extending-chat-widget/javascript-api/v1.0
     */

    // Early exit condition. This is mostly to make eslint happy
    if (!window.LC_API) {
      return;
    }

    showLiveChatWidget();

    if (name) {
      window.LC_API.set_visitor_name(name);
    }

    if (email) {
      window.LC_API.set_visitor_email(email);
    }

    if (isCurrentTabPrimary) {
      // The Live Chat API version 1.0 accepts an array of objects
      const customVariables = [
        { name: 'Forethought Transcript', value: question },
      ];
      window.LC_API.set_custom_variables(customVariables);

      // successfully handed off to LiveChat via LC_API
      window.LC_API.open_chat_window();
    }
  };

  const handoffToLiveChat = ({
    email,
    isCurrentTabPrimary,
    name,
    question,
  }: {
    email: string;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
  }) => {
    /**
     * Hands off to LiveChat using API version >= 2.0
     * https://platform.text.com/docs/extending-chat-widget/javascript-api
     */

    // Early exit condition. This is mostly to make eslint happy
    if (!window.LiveChatWidget) {
      return;
    }

    showLiveChatWidget();

    if (name) {
      window.LiveChatWidget.call('set_customer_name', name);
    }

    if (email) {
      window.LiveChatWidget.call('set_customer_email', email);
    }

    if (isCurrentTabPrimary) {
      window.LiveChatWidget.call('set_session_variables', {
        'Forethought Transcript': question,
      });

      // successfully handed off to LiveChat via LiveChatWidget
      window.LiveChatWidget.call('maximize');
    }
  };

  //#endregion

  //#region attempt handoff

  type HandoffAttempt = {
    additionalParameters: AdditionalParameters;
    department: string;
    email: string;
    featureFlags?: Array<string>;
    forethoughtConversationID: string | null;
    integration: HandoffIntegrations;
    isCurrentTabPrimary: boolean;
    name: string;
    question: string;
    widgetOption: GenericHandoffWidgetOption;
  };

  const attemptHandoff: (handoffAttempt: HandoffAttempt) => void = async ({
    additionalParameters,
    department,
    email,
    featureFlags,
    forethoughtConversationID,
    integration,
    isCurrentTabPrimary,
    name,
    question,
    widgetOption,
  }) => {
    questionGlobal = question;

    const isNextAvailableDeptRoutingEnabled = featureFlags?.includes(
      'next_avail_dept_routing',
    );
    const isZendeskDepartmentVerifyLoopEnabled = featureFlags?.includes(
      'zendesk_department_verify_loop',
    );

    switch (integration) {
      case 'zendesk_messaging':
        const { authToken, customFieldsAndValues, ticketTags } =
          additionalParameters;
        const ticketTagsExist = ticketTags.length > 0;
        const customFieldsExist = customFieldsAndValues.length > 0;

        if (!window.zE) {
          return postHandoffStatus({
            error:
              'window.zE is undefined or Zendesk Messaging iframe is not found',
            integration,
            question,
            success: false,
          });
        }

        window.Forethought('widget', 'hide');
        showZdMessagingAndWidget();
        /*
        We need to make these operations synchronous to avoid race conditions between
        setting a field and creating a ticket
        */
        try {
          if (authToken) {
            window.zE(
              'messenger',
              'loginUser',
              function (callback: (jwt: string) => void) {
                callback(authToken);
              },
            );
          }

          if (ticketTagsExist) {
            await window.zE('messenger:set', 'conversationTags', ticketTags);
          }

          if (customFieldsExist) {
            await window.zE(
              'messenger:set',
              'conversationFields',
              customFieldsAndValues,
            );
          }

          window.zE('messenger:set', 'zIndex', 9999999);
          window.zE('messenger', 'open');
        } catch (error) {
          return postHandoffStatus({
            customFields: customFieldsAndValues,
            error: getExceptionMessage(error),
            integration,
            question,
            success: false,
            ticketTags,
          });
        }

        if (isCurrentTabPrimary) {
          zdMessagingSendMessage(question, () => {
            postHandoffStatus({
              customFields: customFieldsAndValues,
              integration,
              question,
              success: true,
              ticketTags,
            });
          });
        }

        break;
      case 'zendesk':
        const {
          chatTags,
          conversationIdUsedInPreviousSession = '',
          ignoreAgentAvailability,
        } = additionalParameters;

        /**
         * Prevent creating ZD tickets with conversation ID tags from previous
         * conversations
         */
        const removeOldConversationIdChatTag = () => {
          if (!window.zE) {
            return;
          }

          if (conversationIdUsedInPreviousSession) {
            window.zE('webWidget', 'chat:removeTags', [
              `ft-conversation-id_${conversationIdUsedInPreviousSession}`,
            ]);
          }
        };

        if (!window.zE) {
          return postHandoffStatus({
            chatTags,
            departmentReceived: department,
            departmentReceivedName: department,
            email,
            error: 'window.zE is undefined',
            ignoreAgentAvailability,
            integration,
            name,
            question,
            success: false,
          });
        }

        const zdChatWidgetContainer =
          doc.getElementById('launcher')?.parentElement;
        if (zdChatWidgetContainer)
          zdChatWidgetContainer.style.display = 'block';
        //Check if department is available and assign status
        const onlineDepartments = isZendeskDepartmentVerifyLoopEnabled
          ? await getDepartmentsLoop().then(departments =>
              departments.filter(dept => dept.status === 'online'),
            )
          : getOnlineDepartments();
        let departmentName = '';

        // If department is a valid number, use this to look up the department name by id
        const parsedDepId = parseInt(department);
        const departmentId = !isNaN(parsedDepId) ? parsedDepId : null;
        if (departmentId) {
          const departments = getDepartments();
          departmentName =
            departments?.find(dep => dep.id === departmentId)?.name || '';
        } else {
          departmentName = department;
        }

        const isReceivedDeptOnline = onlineDepartments.some(
          dep => dep.name === departmentName,
        );

        if (onlineDepartments.length) {
          if (
            !isNextAvailableDeptRoutingEnabled &&
            departmentName &&
            !isReceivedDeptOnline
          ) {
            return postHandoffStatus({
              chatTags,
              departmentReceived: department,
              departmentReceivedName: departmentName,
              departments: getDepartments(),
              email,
              error: 'Department is offline',
              ignoreAgentAvailability,
              integration,
              isReceivedDepartmentOnline: isReceivedDeptOnline,
              name,
              question,
              success: false,
            });
          }
          try {
            removeOldConversationIdChatTag();
            window.zE('webWidget', 'open');
          } catch (error) {
            return postHandoffStatus({
              chatTags,
              departmentReceived: department,
              departmentReceivedName: departmentName,
              departments: getDepartments(),
              email,
              error:
                error instanceof Error
                  ? error.message
                  : 'Unknown window.zE(...) invocation error',
              ignoreAgentAvailability,
              integration,
              isReceivedDepartmentOnline: isReceivedDeptOnline,
              name,
              question,
              success: false,
            });
          }
          // initializes chat with user name, email and question
          await new Promise<void>(resolve => {
            window.zE &&
              window.zE(async () => {
                if (window.zE) {
                  if (name || email) {
                    try {
                      window.zE.identify({ email, name });
                    } catch (error) {
                      return postHandoffStatus({
                        chatTags,
                        departmentReceived: department,
                        departmentReceivedName: departmentName,
                        departments: getDepartments(),
                        email,
                        error:
                          error instanceof Error
                            ? error.message
                            : 'Unknown window.zE.identify({ email, name }) invocation error',
                        ignoreAgentAvailability,
                        integration,
                        isReceivedDepartmentOnline: isReceivedDeptOnline,
                        name,
                        question,
                        success: false,
                      });
                    }
                  }

                  if (department && isReceivedDeptOnline) {
                    // Fire 5 times with delay (as workaround due to department not being set issue)
                    const numOfTimesToFire = 5;
                    const delayAfterEachFire = 500;
                    const handleDepartmentUpdate = async () => {
                      try {
                        for (let i = 0; i < numOfTimesToFire; ++i) {
                          await wait(delayAfterEachFire);
                          if (window.zE) {
                            const isChattingCurrently = getIsChatting();
                            window.zE &&
                              window.zE('webWidget', 'updateSettings', {
                                webWidget: {
                                  chat: {
                                    departments: {
                                      select: departmentName,
                                    },
                                  },
                                },
                              });
                            postMessage({
                              event: 'zendeskUpdateSettings',
                              updateSettingsData: {
                                department: departmentName,
                                departments: getDepartments(),
                                error: '',
                                executionNumber: i + 1,
                                isReceivedDeptOnline: isReceivedDeptOnline,
                                isSent: true,
                                isSessionActive: isChattingCurrently,
                                timeOfTrackingEvent: Date.now(),
                                ...(departmentId && {
                                  departmentId: departmentId,
                                }),
                              },
                            });
                          }
                        }
                      } catch (error) {
                        postMessage({
                          event: 'zendeskUpdateSettings',
                          updateSettingsData: {
                            department: departmentName,
                            departments: getDepartments(),
                            error:
                              error instanceof Error
                                ? error.message
                                : 'Unknown window.zE(webWidget, updateSettings) invocation error',
                            isReceivedDeptOnline: isReceivedDeptOnline,
                            isSent: false,
                            timeOfTrackingEvent: Date.now(),
                            ...(departmentId && {
                              departmentId: departmentId,
                            }),
                          },
                        });
                      }
                    };
                    await handleDepartmentUpdate();
                  }

                  try {
                    if (chatTags) {
                      window.zE('webWidget', 'chat:addTags', chatTags);
                    }
                  } catch (error) {
                    return postHandoffStatus({
                      chatTags,
                      departmentReceived: department,
                      departmentReceivedName: departmentName,
                      departments: getDepartments(),
                      email,
                      error:
                        error instanceof Error
                          ? error.message
                          : 'Unknown window.zE(webWidget, chat:addTags) invocation error',
                      ignoreAgentAvailability,
                      integration,
                      isReceivedDepartmentOnline: isReceivedDeptOnline,
                      name,
                      question,
                      success: false,
                    });
                  }
                  showZdMessagingAndWidget();
                  if (question && isCurrentTabPrimary) {
                    // Zendesk has asked us to add a timeout to prevent a race condition
                    // where departments may be missing from tickets :(
                    // TODO: Remove this timeout when Zendesk fixes their bug
                    setTimeout(() => {
                      try {
                        window.zE?.('webWidget', 'chat:send', question);
                        resolve();
                      } catch (error) {
                        postHandoffStatus({
                          chatTags,
                          departmentReceived: department,
                          departmentReceivedName: departmentName,
                          departments: getDepartments(),
                          email,
                          error:
                            error instanceof Error
                              ? error.message
                              : "Unknown window.zE('webWidget', 'chat:send', question) invocation error",
                          ignoreAgentAvailability,
                          integration,
                          isReceivedDepartmentOnline: isReceivedDeptOnline,
                          name,
                          question,
                          success: false,
                        });
                      }
                    }, 3000);
                  } else {
                    resolve();
                  }
                }
              });
          });
          postHandoffStatus({
            chatTags,
            departmentReceived: department,
            departmentReceivedName: departmentName,
            departments: getDepartments(),
            email,
            ignoreAgentAvailability,
            integration,
            isReceivedDepartmentOnline: isReceivedDeptOnline,
            name,
            question,
            success: true,
          });
        } else if (ignoreAgentAvailability) {
          showZdMessagingAndWidget();
          try {
            removeOldConversationIdChatTag();
            window.zE('webWidget', 'open');
            window.zE(() => {
              try {
                if (window.zE) {
                  if (name || email) {
                    window.zE.identify({ email, name });
                  }
                  if (chatTags) {
                    window.zE('webWidget', 'chat:addTags', chatTags);
                  }
                  if (departmentName) {
                    window.zE('webWidget', 'updateSettings', {
                      webWidget: {
                        chat: {
                          departments: {
                            select: departmentName,
                          },
                        },
                      },
                    });
                  }
                  setTimeout(() => {
                    if (question && isCurrentTabPrimary) {
                      // Checking window.zE again due to after the delay won't know if its still available
                      window.zE?.('webWidget', 'chat:send', question);
                    }
                  }, 3000);
                }
              } catch (error) {
                return postHandoffStatus({
                  chatTags,
                  departmentReceived: department,
                  departmentReceivedName: departmentName,
                  departments: getDepartments(),
                  email,
                  error:
                    error instanceof Error
                      ? error.message
                      : 'Unknown window.zE(...) invocation error',
                  ignoreAgentAvailability,
                  integration,
                  isReceivedDepartmentOnline: isReceivedDeptOnline,
                  name,
                  question,
                  success: false,
                });
              }
            });
          } catch (error) {
            return postHandoffStatus({
              chatTags,
              departmentReceived: department,
              departmentReceivedName: departmentName,
              departments: getDepartments(),
              email,
              error:
                error instanceof Error
                  ? error.message
                  : 'Unknown window.zE(...) invocation error',
              ignoreAgentAvailability,
              integration,
              isReceivedDepartmentOnline: isReceivedDeptOnline,
              name,
              question,
              success: false,
            });
          }
          postHandoffStatus({
            chatTags,
            departmentReceived: department,
            departmentReceivedName: departmentName,
            departments: getDepartments(),
            email,
            ignoreAgentAvailability,
            integration,
            isReceivedDepartmentOnline: isReceivedDeptOnline,
            name,
            question,
            success: true,
          });
        } else {
          // Send message failure to continue usual flow
          postHandoffStatus({
            chatTags,
            departmentReceived: department,
            departmentReceivedName: departmentName,
            departments: getDepartments(),
            email,
            error: 'All departments are offline',
            ignoreAgentAvailability,
            integration,
            isReceivedDepartmentOnline: isReceivedDeptOnline,
            name,
            question,
            success: false,
          });
        }
        break;
      case 'salesforce':
        const {
          extraPrechatFormDetails = [] as FormattedSalesforceExtraPrechatFormDetails[],
          manually_click_salesforce_button = false,
          manually_click_timeout = 0,
          ...remainingAdditionalParameters
        } = additionalParameters;

        if (!window.embedded_svc) {
          return postHandoffStatus({
            error: 'window.embedded_svc is undefined',
            integration,
            manuallyClickSalesforceButton: manually_click_salesforce_button,
            success: false,
          });
        }
        const salesforceSettingsLog: Record<string, any> = {};

        if (!salesforceAfterDestroyCallbackSet) {
          if (!window.embedded_svc?.addEventHandler) {
            return postHandoffStatus({
              error: 'window.embedded_svc.addEventHandler is undefined',
              integration,
              manuallyClickSalesforceButton: manually_click_salesforce_button,
              success: false,
            });
          }
          window.embedded_svc.addEventHandler(
            'afterDestroy',
            salesforceChatEnded,
          );
          salesforceAfterDestroyCallbackSet = true;
        }

        // Show salesforce chat button in case it was hidden before
        showSalesforceWidget();

        // Hide our widget
        window.Forethought('widget', 'hide');

        if (isCurrentTabPrimary) {
          /**
           * dont want to send variables unless we have them some orgs are
           * configured for Name, some for FirstName and LastName, our actions
           * only sends name to the frontend but we want to cover every scenario
           */
          const prechatNameFields: {
            FirstName?: string;
            LastName?: string;
            Name?: string;
          } = {};
          if (name) {
            const { firstName, lastName } =
              salesforceSplitNameIntoFirstAndLast(name);
            prechatNameFields.Name = name;
            prechatNameFields.FirstName = firstName;
            prechatNameFields.LastName = lastName;
          }

          const startChatFields = {
            extraPrechatFormDetails,
            prepopulatedPrechatFields: {
              Email: email,
              Subject: question,
              ...prechatNameFields,
              ...remainingAdditionalParameters,
            },
          };
          salesforceSettingsLog.startChatFields = { ...startChatFields };

          if (manually_click_salesforce_button) {
            /**
             * setup callback for salesforce afterMaximize to call startchat
             * addEventHandler returns nothing that can be removed and
             * window.embedded_svc.removeEventHandler is not a function so we are
             * using a workaround with a dumb boolean
             */
            let runStartChat = true;

            // setup salesforce event listener
            if (!window.embedded_svc?.addEventHandler) {
              return postHandoffStatus({
                error: 'window.embedded_svc.addEventHandler is undefined',
                integration,
                manuallyClickSalesforceButton: manually_click_salesforce_button,
                success: false,
              });
            }
            window.embedded_svc.addEventHandler('afterMaximize', () => {
              setTimeout(() => {
                if (runStartChat) {
                  if (!window.embedded_svc.liveAgentAPI?.startChat) {
                    return postHandoffStatus({
                      error:
                        'window.embedded_svc.liveAgentAPI.startChat is undefined',
                      integration,
                      manuallyClickSalesforceButton:
                        manually_click_salesforce_button,
                      success: false,
                    });
                  }
                  window.embedded_svc.liveAgentAPI
                    .startChat({ ...startChatFields })
                    .then(() => {
                      runStartChat = false;
                    });
                }
              }, manually_click_timeout);
            });

            // manually click salesforceChatButton
            const salesforceChatButton: HTMLButtonElement | null =
              document.querySelector('div.embeddedServiceHelpButton button');
            if (salesforceChatButton) {
              // clicking will cause afterMaximize callback to trigger when finished loading which calls startChat
              salesforceChatButton.click();
              postHandoffStatus({
                ...salesforceSettingsLog,
                integration,
                manuallyClickSalesforceButton: manually_click_salesforce_button,
                success: true,
              });
            } else {
              postHandoffStatus({
                ...salesforceSettingsLog,
                error: 'Salesforce chat button is not found',
                integration,
                manuallyClickSalesforceButton: manually_click_salesforce_button,
                success: false,
              });
            }
          } else {
            if (!window.embedded_svc.liveAgentAPI?.startChat) {
              return postHandoffStatus({
                error:
                  'window.embedded_svc.liveAgentAPI.startChat is undefined',
                integration,
                manuallyClickSalesforceButton: manually_click_salesforce_button,
                success: false,
              });
            }
            window.embedded_svc.liveAgentAPI.startChat({ ...startChatFields });
            postHandoffStatus({
              ...salesforceSettingsLog,
              integration,
              manuallyClickSalesforceButton: manually_click_salesforce_button,
              success: true,
            });
          }
        }
        break;
      case 'kustomer':
        if (!window.Kustomer) {
          return postHandoffStatus({
            customFields: { ...additionalParameters.customFields },
            error: 'window.Kustomer is undefined',
            integration,
            question,
            success: false,
          });
        }

        // https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-is-chat-available
        const isChatAvailable = window.Kustomer.isChatAvailable();
        if (isChatAvailable === undefined) {
          return postHandoffStatus({
            customFields: { ...additionalParameters.customFields },
            error: 'Kustomer.isChatAvailable() returned undefined',
            integration,
            question,
            success: false,
          });
        }
        const { availability } = isChatAvailable;
        if (availability === 'disabled' || availability === 'hidden') {
          return postHandoffStatus({
            customFields: { ...additionalParameters.customFields },
            error: `Chat is ${availability}`,
            integration,
            question,
            success: false,
            widgetAvailability: availability,
          });
        }

        if (isCurrentTabPrimary) {
          try {
            // https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-describe-customer
            if (email) {
              window.Kustomer.describeCustomer({
                attributes: { emails: [email] },
              });
            }
            // https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-create-conversation
            // If question is empty or undefined, pass a placeholder
            window.Kustomer.createConversation(
              {
                customAttributes: { ...additionalParameters.customFields },
                message: question || 'Conversation via Forethought',
                ...(additionalParameters.assistantId
                  ? { assistantId: additionalParameters.assistantId }
                  : {}),
              },
              function (response?: KustomerCallbackResponse, error?: Error) {
                if (error || !response) {
                  return postHandoffStatus({
                    customFields: { ...additionalParameters.customFields },
                    error: getExceptionMessage(error),
                    integration,
                    question,
                    success: false,
                  });
                } else {
                  const includeAttachments = true;
                  const includeTranscript = true;
                  postUpdateHelpdeskConversation(
                    integration,
                    response.conversationId,
                    forethoughtConversationID,
                    includeAttachments,
                    includeTranscript,
                  );
                  showKustomerWidget();
                  window.Forethought('widget', 'hide');
                  postHandoffStatus({
                    customFields: { ...additionalParameters.customFields },
                    integration,
                    question,
                    success: true,
                    widgetAvailability: availability,
                  });
                }
              },
            );
          } catch (err) {
            return postHandoffStatus({
              customFields: { ...additionalParameters.customFields },
              error: getExceptionMessage(err),
              integration,
              question,
              success: false,
            });
          }
        }
        break;
      case 'intercom':
        if (!window.Intercom) {
          return postHandoffStatus({
            autoSendConfig: additionalParameters.autoSendConfig,
            email,
            error: 'window.Intercom is undefined',
            identityVerificationEnabled:
              additionalParameters.identityVerificationEnabled,
            integration,
            name,
            question,
            success: false,
            userid: additionalParameters.userid,
          });
        }
        try {
          handOffToIntercom({
            autoSendConfig: additionalParameters.autoSendConfig,
            email,
            identityVerificationEnabled:
              additionalParameters.identityVerificationEnabled,
            isCurrentTabPrimary: isCurrentTabPrimary,
            name,
            question,
            userid: additionalParameters.userid,
          });
        } catch (err) {
          // Note - this currently does not catch issues with autosend button
          // clicks
          return postHandoffStatus({
            autoSendConfig: additionalParameters.autoSendConfig,
            email,
            error: getExceptionMessage(err),
            identityVerificationEnabled:
              additionalParameters.identityVerificationEnabled,
            integration,
            name,
            question,
            success: false,
            userid: additionalParameters.userid,
          });
        }
        postHandoffStatus({
          autoSendConfig: additionalParameters.autoSendConfig,
          email,
          identityVerificationEnabled:
            additionalParameters.identityVerificationEnabled,
          integration,
          name,
          question,
          success: true,
          userid: additionalParameters.userid,
        });
        break;
      case 'snap_engage':
        if (
          !window.SnapEngage ||
          !window.SnapEngage.startLink ||
          !window.SnapEngage.sendTextToChat
        ) {
          return postHandoffStatus({
            additionalParameters,
            email,
            error:
              'window.SnapEngage is undefined, or is missing necessary functions',
            integration,
            name,
            question,
            success: false,
          });
        }
        try {
          handOffToSnapEngage({
            additionalParameters,
            email,
            isCurrentTabPrimary,
            name,
            question,
          });
        } catch (err) {
          return postHandoffStatus({
            additionalParameters,
            email,
            error: getExceptionMessage(err),
            integration,
            name,
            question,
            success: false,
          });
        }
        postHandoffStatus({
          additionalParameters,
          email,
          integration,
          name,
          question,
          success: true,
        });
        break;
      case 'drift':
        if (!window.drift) {
          return postHandoffStatus({
            email,
            error: 'window.drift is undefined',
            integration,
            name,
            question,
            success: false,
          });
        }
        try {
          handOffToDrift({ email, isCurrentTabPrimary, name, question });
        } catch (err) {
          return postHandoffStatus({
            email,
            error: getExceptionMessage(err),
            integration,
            name,
            question,
            success: false,
          });
        }
        postHandoffStatus({
          email,
          integration,
          name,
          question,
          success: true,
        });
        break;
      case 'generic':
        try {
          genericHandoff(widgetOption, { ...additionalParameters });
          postHandoffStatus(
            {
              ...additionalParameters,
              integration,
              success: true,
              widgetOption,
            },
            false,
          );
        } catch (err) {
          postHandoffStatus(
            {
              ...additionalParameters,
              error: getExceptionMessage(err),
              integration,
              success: false,
              widgetOption,
            },
            false,
          );
        }
        break;
      case 'brand_embassy':
        const { autoStartSession, customFields } = additionalParameters;
        if (!window.brandembassy) {
          return postHandoffStatus({
            autoStartSession,
            customFields,
            error: 'window.brandembassy is undefined',
            integration,
            name,
            question,
            success: false,
          });
        }
        try {
          handoffToBrandEmbassy({
            autoStartSession,
            customFields,
            isCurrentTabPrimary,
            name,
            question,
          });
        } catch (err) {
          return postHandoffStatus({
            autoStartSession,
            customFields,
            error: getExceptionMessage(err),
            integration,
            name,
            question,
            success: false,
          });
        }
        postHandoffStatus({
          autoStartSession,
          customFields,
          integration,
          name,
          question,
          success: true,
        });
        break;
      case 'gorgias':
        if (!window.GorgiasChat) {
          return postHandoffStatus({
            error: 'window.GorgiasChat is undefined',
            integration,
            question,
            success: false,
          });
        }
        try {
          handoffToGorgias({ isCurrentTabPrimary, question });
        } catch (err) {
          return postHandoffStatus({
            error: getExceptionMessage(err),
            integration,
            question,
            success: false,
          });
        }
        postHandoffStatus({
          integration,
          question,
          success: true,
        });
        break;
      case 'freshchat': {
        const { conversationProperties, firstName, lastName, tags } =
          additionalParameters;
        if (!window.fcWidget) {
          return postHandoffStatus({
            conversationProperties,
            email,
            error: 'window.fcWidget is not found',
            firstName,
            forethoughtConversationID,
            integration,
            lastName,
            question,
            success: false,
            tags,
          });
        }
        try {
          handoffToFreshchat({
            conversationProperties,
            email,
            firstName,
            forethoughtConversationID,
            isCurrentTabPrimary,
            lastName,
            question,
            tags,
          });
        } catch (err) {
          return postHandoffStatus({
            conversationProperties,
            email,
            error: getExceptionMessage(err),
            firstName,
            forethoughtConversationID,
            integration,
            lastName,
            question,
            success: false,
            tags,
          });
        }
        postHandoffStatus({
          conversationProperties,
          email,
          firstName,
          forethoughtConversationID,
          integration,
          lastName,
          question,
          success: true,
          tags,
        });
        break;
      }
      case 'salesforce_messaging': {
        const { clearSessionBeforeHandoff = false, hiddenFields = {} } =
          additionalParameters;
        if (!window.embeddedservice_bootstrap) {
          return postHandoffStatus({
            clearSessionBeforeHandoff,
            error: 'window.embeddedservice_bootstrap is undefined',
            hiddenFields,
            integration,
            question,
            success: false,
          });
        }
        try {
          await handoffToSalesforceMessaging({
            clearSessionBeforeHandoff,
            forethoughtConversationID,
            hiddenFields,
            isCurrentTabPrimary,
          });
        } catch (err) {
          return postHandoffStatus({
            error: getExceptionMessage(err),
            hiddenFields,
            integration,
            question,
            success: false,
          });
        }
        postHandoffStatus({
          hiddenFields,
          integration,
          question,
          success: true,
        });
        break;
      }
      case 'live_chat':
        if (
          window.LiveChatWidget === undefined &&
          window.LC_API === undefined
        ) {
          // If we can use neither method of handoff, report handoff failure
          return postHandoffStatus({
            email,
            error: 'both window.LiveChatWidget and window.LC_API are undefined',
            integration: 'live_chat',
            name,
            question,
            success: false,
          });
        }
        try {
          if (window.LiveChatWidget) {
            handoffToLiveChat({ email, isCurrentTabPrimary, name, question });
          } else if (window.LC_API) {
            handoffToLiveChatV1Api({
              email,
              isCurrentTabPrimary,
              name,
              question,
            });
          }
        } catch (err) {
          return postHandoffStatus({
            email,
            error: getExceptionMessage(err),
            integration: 'live_chat',
            name,
            question,
            success: false,
          });
        }
        // If either LiveChat or LC_API are defined, report a successful handoff
        postHandoffStatus({
          email,
          integration: 'live_chat',
          name,
          question,
          success: true,
        });
        break;
      default:
        postHandoffStatus({
          error: `Unknown integration: ${integration}`,
          integration,
          question,
          success: false,
        });
        break;
    }
  };

  const postHandoffStatus = (handoffData: HandoffData, hideWidget = true) => {
    // eslint-disable-next-line no-console -- needed for debugging in production
    console.log(`Forethought ${handoffData.integration} handoff`, handoffData);

    // update conversation
    postMessage({
      event: 'handoffStatus',
      handoffFailureMessage: handoffData.error,
      hideWidget,
      success: handoffData.success,
    });

    // post message to parent page
    const handoffCompletedEventData: ForethoughtWidgetIntegrationHandoffCompletedEvent['data'] =
      {
        event: 'forethoughtWidgetIntegrationHandoffCompleted',
        handoffData: handoffData,
        success: handoffData.success,
      };
    window.postMessage(handoffCompletedEventData);

    // tracking event
    postMessage({
      event: 'handoffTrackingEvent',
      handoffData,
    });
  };

  //#endregion general handoff

  //#region general event listeners/start

  window.addEventListener('message', event => {
    const data = event.data;
    const dataData = data?.data;
    const method = data?.method;
    if (method === 'liveagent.restored') refreshingPageWithChat();
    if (method === 'liveagent.initialized') {
      showSalesforceWidget();
      window.Forethought('widget', 'hide');
    }
    if (dataData?.event === 'chasitorChatEstablished') sfMessagingSendMessage();
  });

  window.addEventListener('message', event => {
    if (!isEventTrusted(event)) {
      return;
    }

    if (event.data.event === 'forethoughtWidgetLoaded') {
      if (window.zE) {
        // DO NOT hide forethought and show zendesk widget when doing one chat
        if (event.data.isLiveChatMode) {
          hideZdMessagingAndWidget();
        } else {
          callWithRetry(() => zdHandOffAttempt(window.zE));
        }
      } else {
        hideZdMessagingAndWidget();
      }

      // hide only once we know we aren't going to show their widget
      if (window.LC_API) {
        callWithRetry(liveChatHandoffAttempt).then(liveChatWidgetShown => {
          if (!liveChatWidgetShown) {
            hideLiveChatWidget();
          }
        });
      } else {
        hideLiveChatWidget();
      }

      hideSalesforceWidget();
      hideKustomerChatWidget();
      if (hideIntercomWidget !== 'false') {
        hideIntercomChatIconAndWidget();
      }
      hideSnapEngageChatIconAndWidget();
      hideDriftChatIconAndWidget();
      hideBrandEmbassyIconAndWidget();
      hideGorgiasIconAndWidget();
      hideFreshchatIconAndWidget();
      hideSalesforceMessagingIconAndWidget();

      return;
    }

    if (event.data.event === 'forethoughtWidgetIntegrationHandoff') {
      attemptHandoff(event.data);

      return;
    }
  });

  //#endregion general event listeners/start
};
