export const V2_WORKFLOWS_URL = 'workflow/v2';

export const apiRouteV2 = {
  'get-translated-csat-text': {
    method: 'get',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/csat-text`,
  },
  'get-widget-components': {
    method: 'get',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/widget-components`,
  },
  'init-intent-conversation': {
    method: 'post',
    url: `/${V2_WORKFLOWS_URL}/conversation`,
  },
  'interactive-email-deflection-feedback': {
    method: 'post',
    url: `/${V2_WORKFLOWS_URL}/interactive-email-conversation/{conversationId}/deflection-feedback`,
  },
  'restart-intent-conversation': {
    method: 'put',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/restart`,
  },
  'send-conversation-feedback': {
    method: 'put',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/feedback`,
  },
  'send-free-form-query': {
    method: 'put',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/free-form-query`,
  },
  'submit-intent-csat': {
    method: 'post',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/csat`,
  },
  'update-helpdesk-conversation': {
    method: 'post',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}/update-helpdesk-conversation`,
  },
  'update-intent-conversation': {
    method: 'put',
    url: `/${V2_WORKFLOWS_URL}/conversation/{conversationId}`,
  },
} as const;
