export const FORETHOUGHT_IFRAME_ID = 'forethought-chat';

// margins
export const DEFAULT_MARGIN = 48;
export const CUSTOM_OFFSET_MARGIN = 24;

// height
export const SMALL_HEIGHT_BREAKPOINT = 700;
const WIDGET_MAX_HEIGHT = 800;
export const WIDGET_TOTAL_MAX_HEIGHT = WIDGET_MAX_HEIGHT - 2;

// width
export const LARGE_WIDGET_WIDTH = 640;
export const SMALL_WIDGET_WIDTH = 400;
export const MOBILE_WIDTH_BREAKPOINT = 480;
export const DEFAULT_OFFSET_EXTRA_WIDTH = 6;
export const CUSTOM_OFFSET_EXTRA_WIDTH = 30;
export const LARGE_WIDGET_TOTAL_WIDTH = LARGE_WIDGET_WIDTH + DEFAULT_MARGIN;

// misc
export const MESSAGE_PADDING = '14px 16px';
